import React, { useEffect, useState } from 'react'
import { AgGridReact } from 'ag-grid-react'
import TextField from '@mui/material/TextField';
import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-quartz.css"
import "ag-grid-community/styles/ag-theme-material.css"
import axios from 'axios';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import "../App.css";

import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const DataTable = ({isAdmin}) => {

  const [rowData, setRowData] = useState([])
  const [originalData, setOriginalData] = useState([])
  const [csvData, setCsvData] = useState([]);
  const [marka, setMarka] = useState('');
  const [markalar, setMarkalar] = useState([]);
  const [mevsim, setMevsim] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');

  
  const [colDefs, setColDefs] = useState([
    ...(isAdmin ? [
      { headerName: "IP",
       field: "IP",
       minWidth: 140
      },
    ] : []),
      { headerName: "Ebat", field: "Ebat", minWidth: 120 },
      { headerName: "Marka", field: "Marka", minWidth: 120 },
      { headerName: "Model", field: "Model", minWidth: 140 },
      { headerName: "Endex", field: "Endex", minWidth: 85 },
      { 
        headerName: "Adet",
        field: "Adet",
        valueGetter: p => {
          return Number(p.data.Adet) > 8 ? '8' : p.data.Adet;
        },
        minWidth: 80
        
      },
      { headerName: "Dot", field: "Dot", minWidth: 80 },
      ...(isAdmin ? [
        { headerName: "Satış", field: "Satis", minWidth: 95  },
        { headerName: "Takım", field: "Takim", minWidth: 100  },
      ] : []),
      { headerName: "B2B Fiyat", field: "B2B", minWidth: 120 },
      { headerName: "Konum", field: "Konum", minWidth: 120  },
      { headerName: "Mevsim", field: "Mevsim", minWidth: 100  },
    ])
    
  const defaultColDef = {
    sortable: true,
    flex: 1,
  }
  
  useEffect(() => {
    fetchCSVData();
  }, []); 
    
  const fetchCSVData = () => {
    const url = "https://next-pro-nine-rho.vercel.app/api";

    // GET isteği yaparak veriyi al
    fetch(url)
      .then(response => {
        // Yanıtın durumunu kontrol et
        if (response.ok) {
          return response.json();
        }
        throw new Error('Hata! İstek başarısız. Durum kodu: ' + response.status);
      })
      .then(values => {
        const data = [];
        const columnNames = values[0];
        const columnIndices = {};
        
        columnNames.forEach((name, index) => {
          columnIndices[name] = index;
        });
        
        values.slice(1).forEach(row => {
          const userData = {};
          columnNames.forEach((name, index) => {
            userData[name] = index < row.length ? row[index] : '';
          });
          data.push(userData);
        });
        
            setOriginalData(data.filter(item => item.Adet !== '0').map(item =>
                ({
                  "Ebat": item.Ebat,
                  "Marka": item.Marka,
                  "Model":  item.Model,
                  "Endex":  item.Endex,
                  "Adet":  item.Adet,
                  "Dot":  item.Dot,
                  "Konum":  item.Konum,
                  "Mevsim":  item.Mevsim,
                  "B2B":  item.B2B,
                  ...(isAdmin ? { 
                    "Satis" : item.Satis,
                    "Takim":  item.Takim,
                    "IP": item.IP,
                   } : {} )
                }
              )
              ))   
              setMarkalar([...new Set(data.map(item => item.Marka))]);

        // **

      })
      .catch(error => {
        console.error(error); // Hata durumunda hatayı yakalayıp yazdırın
      });

  }

  const handleChange = (event) => {
    setMarka(event.target.value);
  };

  const handleChangeMevsim = (event) => {
    setMevsim(event.target.value);
  };

  useEffect(() => {
    setRowData(originalData);
  }, [originalData]);

  const filter = () => {

    let filteredData = originalData;

    if (marka) {
      filteredData = filteredData.filter(item => item.Marka === marka);
    }

    if (mevsim) {
      filteredData = filteredData.filter(item => item.Mevsim === mevsim);
    }

    if (searchKeyword) {
      filteredData = filteredData.filter(item =>
        item.Ebat.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item.Ebat.toLowerCase().replace("/","").replace("r", "").includes(searchKeyword.toLowerCase()) ||
        item.Marka.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item.Model.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item.Endex.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item.Adet.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item.Dot.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item.Konum.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item.Mevsim.toLowerCase().includes(searchKeyword.toLowerCase()) ||
        item.B2B.toLowerCase().includes(searchKeyword.toLowerCase())
      );
    }

    setRowData(filteredData);
  };

  useEffect(() => {
    filter();
  }, [marka, mevsim, searchKeyword]);

  return (
    <div >
      <div 
        style={{
          marginBottom: 10,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '90%',
          }}
        >
          <TextField 
            id="standard-basic" 
            label="Ara" 
            variant="standard"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            sx={{ minWidth: '48%', mr:1 }}
          />

          <FormControl variant="standard" sx={{ minWidth: '24%', mr:1 }}>
            <InputLabel id="select-marka-label">Marka</InputLabel>
            <Select
              labelId="select-marka-label"
              id="select-marka"
              value={marka}
              onChange={handleChange}
              label="Marka"
            >
              <MenuItem key={0} value={''}>Marka seçiniz</MenuItem>
              {markalar.map(marka => (
                <MenuItem key={marka} value={marka}>{marka}</MenuItem>
              ))
              }
            </Select>
          </FormControl>

          <FormControl variant="standard" sx={{ minWidth: '24%', mr:1 }}>
            <InputLabel id="select-mevsim-label">Mevsim</InputLabel>
            <Select
              labelId="select-mevsim-label"
              id="select-mevsim"
              value={mevsim}
              onChange={handleChangeMevsim}
              label="mevsim"
            >
              <MenuItem key={0} value={''}>Mevsim seçiniz</MenuItem>
              <MenuItem value={'Yaz'}>Yaz</MenuItem>
              <MenuItem value={'Kış'}>Kış</MenuItem>
              <MenuItem value={'4 Mevsim'}>4 Mevsim</MenuItem>
            </Select>
          </FormControl>
        </Box>

      </div>
      <div 
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 30,
        }}
      >
        <Stack direction="row" spacing={1}>
          {searchKeyword && <Chip label={searchKeyword} onDelete={() => setSearchKeyword('')} /> }
          {marka && <Chip label={marka} onDelete={() => setMarka('')} /> }
          {mevsim && <Chip label={mevsim} onDelete={() => setMevsim('')} /> }
        </Stack>
      </div>
      <div 
        style={{ 
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        <Box       
          className="ag-theme-material"
          // className="ag-theme-quartz"
          // style={{ 
          //   height: 'calc(100vh - 350px)', 
          //   width: '90%',
          // }}
          sx={{
            width: {
              xs: '100%',
              md: '90%',
            },
            height: { 
              xs: 'calc(100vh - 150px)',
              md: 'calc(100vh - 350px)',
            },
          }}
        >
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            rowStyle={{
              background: '#f2f2f2'
            }}
            getRowStyle={(params) => {
              if (params.node.rowIndex % 2 === 0) {
                return { background: 'white' };
            }
            }}
            pagination={true}
            paginationAutoPageSize={true}
            defaultColDef={defaultColDef}
          />
        </Box>
      </div>
    </div>
  )
}

export default DataTable