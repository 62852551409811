import React from 'react'

import "ag-grid-community/styles/ag-grid.css"
import "ag-grid-community/styles/ag-theme-quartz.css"
import "ag-grid-community/styles/ag-theme-material.css"

import Box from '@mui/material/Box';

const Banner = ({user, bannerPath}) => {

  return (
    <Box
      sx={{
        marginTop: 10,
        marginBottom: 2,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        component="img"
        sx={{
          width: { xs: '90%', md: '60%' },
        }}
        src="banner1.jpeg"
      />
    </Box>
      
  )
}

export default Banner