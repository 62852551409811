import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Swal from 'sweetalert2'

function AppAppBar({ user, setUser }) {

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor:
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
                overflow: 'hidden',
              }}
            >
                <MenuItem
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="h3" color="text.primary">
                    b2b.balastik.com
                  </Typography>
                </MenuItem>
         
            </Box>
            <Box
              sx={{
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              <Button
                color="success"
                variant="contained"
                startIcon={<PersonIcon />}
                size="medium"
                onClick={() => null }
                sx={{ mr: 3 }}
              >
                {user?.ad + ' ' + user?.soyad  || ''}
              </Button>
                <a 
                  href="https://wa.me/905412375225"
                  target="_blank"
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <Button 
                    color="success"
                    variant="outlined"
                    startIcon={<WhatsAppIcon />}
                    sx={{ mr: 3 }}
                  >
                      WhatsApp
                  </Button>
                </a>
              <Button
                color="error"
                variant="outlined"
                startIcon={<LogoutIcon />}
                size="medium"
                component="a"
                onClick={() => {
                    Swal.fire({
                        title: "Çıkış yapmak istediğinize emin misiniz?",
                        icon: "warning",
                        showCancelButton: true,
                        cancelButtonColor: "#d33",
                        confirmButtonColor: "#3085d6",
                        cancelButtonText: "İptal",
                        confirmButtonText: "Çıkış Yap",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            setUser(null)
                        }
                      });
                }
                }
              >
                Çıkış Yap
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;