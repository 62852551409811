import { useState } from "react";
import DataTable from "./components/DataTable";
import Login from "./components/Login";
import AppAppBar from "./components/AppAppBar";
import Banner from "./components/Banner";
import ResponsiveAppBar from "./components/Navbar";

const App = () => {

  const [user, setUser] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)

  return (
    <div className="App">
      {user 
      ?  ( <>
          <ResponsiveAppBar
            user={user}
            setUser={setUser}
          />
          {/* <AppAppBar
            user={user}
            setUser={setUser}
          /> */}
          <Banner/>
          <DataTable
            isAdmin={isAdmin}
          /> 
        </>)
      : <Login 
          user={user}
          setUser={setUser}
          setIsAdmin={setIsAdmin}
        />
      }
    </div>
  );
}

export default App;
