import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PersonIcon from '@mui/icons-material/Person';
import Swal from 'sweetalert2'
import LogoutIcon from '@mui/icons-material/Logout';

function ResponsiveAppBar({user, setUser}) {
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar 
        position="fixed"
        sx={{
            bgcolor: '#0f1215',
        }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
            //   fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            b2b.balastik.com
          </Typography>
          <Typography
            variant="h5"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            b2b.balastik.com
          </Typography>

          <Box sx={{ flexGrow: 1, display:'flex', justifyContent:'flex-end' }}>
            <Tooltip title="Menü">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <MenuIcon 
                color="white"
                sx={{ color: '#fff'}}
                fontSize="large"
              />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
                <MenuItem onClick={handleCloseUserMenu}>
                    <Button
                        color="info"
                        variant="contained"
                        startIcon={<PersonIcon />}
                        size="medium"
                        sx={{ width: '100%'}}
                        onClick={() => null }
                    >
                        {user?.ad + ' ' + user?.soyad  || ''}
                    </Button>
                </MenuItem>
                <MenuItem onClick={handleCloseUserMenu}>
                    <Button
                        color="error"
                        variant="outlined"
                        startIcon={<LogoutIcon />}
                        size="medium"
                        sx={{ width: '100%'}}
                        component="a"
                        onClick={() => {
                            Swal.fire({
                                title: "Çıkış yapmak istediğinize emin misiniz?",
                                icon: "warning",
                                showCancelButton: true,
                                cancelButtonColor: "#d33",
                                confirmButtonColor: "#3085d6",
                                cancelButtonText: "İptal",
                                confirmButtonText: "Çıkış Yap",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    setUser(null)
                                }
                            });
                        }
                        }
                    >
                        Çıkış Yap
                    </Button>
                </MenuItem>

                <MenuItem onClick={handleCloseUserMenu}>
                    <a 
                        href="https://wa.me/905412375225"
                        target="_blank"
                        style={{ textDecoration: 'none', color: 'inherit', width: '100%'}}
                    >
                        <Button 
                        color="success"
                        variant="outlined"
                        startIcon={<WhatsAppIcon />}
                        >
                            WhatsApp
                        </Button>
                    </a>
                </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;